import axios from "axios";

const api = axios.create({
  baseURL: "https://bott-api.oneclicktraveltech.com"
});

export const intialListing = () => {
  return api.get("/question/initial-listing");
};

export const questionListing = () => {
  return api.get("/question/hotel-question-listing", {
    params: {
      optionType: "hotel",
    },
  });
};

export const searchCity = (userInput) => {
  return api.get(`/hotel/search-city/${userInput}`);
};

export const getHotelList = (
  cityPpn,
  checkIn,
  checkOut,
  rooms,
  adults,
  children,
  logId
) => {
  return api.post("/hotel/hotel-listing", {
    cityPpn: cityPpn,
    checkIn: checkIn,
    checkOut: checkOut,
    adults: adults,
    children: children,
    rooms: rooms,
    logId: logId,
  });
};

export const getFilterList = (
  cityPpn,
  checkIn,
  checkOut,
  rooms,
  adults,
  children,
  sortBy
) => {
  return api.post("/hotel/hotel-listing", {
    cityPpn: cityPpn,
    checkIn: checkIn,
    checkOut: checkOut,
    adults: adults,
    children: children,
    rooms: rooms,
    sortBy: sortBy,
  });
};

export const getRoomList = (ppnBundle) => {
  return api.post("/hotel/room-details/", {
    ppnBundle: ppnBundle,
  });
};

export const confirmRoom = (ppnBundle) => {
  return api.post("/hotel/room-available", {
    ppnBundle: ppnBundle,
  });
};

export const userDetails = () => {
  return api.get("/question/user-question-listing", {
    params: {
      optionType: "user",
    },
  });
};

export const postUserDetails = (
  name_first,
  name_last,
  email,
  phone_number,
  address_line_one,
  address_city,
  postal_code,
  country_code,
  ppnBookBundle,
  hotelImage,
  roomName,
  roomImage
) => {
  return api.post("/hotel/hotel-booking-details", {
    name_first: name_first,
    name_last: name_last,
    email: email,
    phone_number: phone_number,
    address_line_one: address_line_one,
    address_city: address_city,
    postal_code: postal_code,
    country_code: country_code,
    ppnBookBundle: ppnBookBundle,
    hotelImage: hotelImage,
    roomName: roomName,
    roomImage:roomImage
  });
};

export const confirmHotelBooking = (transactionId, bookingId) => {
  return api.post("/hotel/confirm-booking", {
    transactionId: transactionId,
    bookingId: bookingId,
  });
};

export const bookingDetailsQuestion = () => {
  return api.get("/question/booking-question-listing", {
    params: {
      optionType: "booking",
    },
  });
};

export const getBookingDetails = (email) => {
  return api.post("/hotel/get-booking-details", {
    email: email,
  });
};

export const ConfirmBookingDetails = (bookingId) => {
  return api.post("/hotel/booking-status", {
    bookingId: bookingId,
  });
};

export const ContactUsQuestions = () => {
  return api.get("/question/question-listing", {
    params: {
      optionType: "contact",
    },
  });
};

export const ContactUsDetails = (fullName, email, mob, message) => {
  return api.post("/hotel/contact-us", {
    fullName: fullName,
    email: email,
    mob: mob,
    message: message,
  });
};

export default api;