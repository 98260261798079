import React from "react";
import "../HotelDetails/HotelDetails.css";
const HotelDetails = ({
  data,
  hotelClicked,
  questionData,
  selectedOption,
  about,
  handleAboutUsClick,
  handleHotelClick,
  displayQuestions,
  currentQuestionIndex,
  userinputarray,
  selectedCity,
  messagesEndRef,
  handleBookingClick,
  bookingDetails,
  isTyping,
  bookDetailsData,
  detailsBtn,
  bookingemail,
  isTyping2,
  bookingDetailsEmailMsg,
  bookingDetailsLoader,
  handleContactUsClick,
  contactUs,
  currentQuestionContactIndex,
  printSuccessBooking,
  contactmesssage,
}) => {
  const date = new Date();
  const isoDateString = date.toISOString();

  return (
    <>
      {data.question && bookingDetails && (
        <div className="question-booking">{data.question}</div>
      )}
      {data.question && (
        <>
          {!bookingDetails && !about && !contactUs && (
            <div className="question-div">
              <div className="innr_box" ref={messagesEndRef}>
                {data.question}
              </div>
            </div>
          )}
          {hotelClicked && (
            <div
              className="user-input-value"
              style={{ marginTop: "0px", marginBottom: "10px" }}
            >
              <div className="innr_box">Hotel</div>
            </div>
          )}
          {!bookingDetails && !about && !hotelClicked && !contactUs && (
            <div className="options_wrapper">
              {data?.options?.map(
                (option) =>
                  !bookingDetails &&
                  !contactUs && (
                    <button
                      key={option.id}
                      className="option"
                      onClick={() => {
                        if (option?.option === "Hotel") {
                          handleHotelClick(option);
                        }
                        if (option?.option === "About Us") {
                          handleAboutUsClick(option);
                        }
                        if (option?.option === "Booking Details") {
                          handleBookingClick(option);
                        }
                        if (option?.option === "Contact Us") {
                          handleContactUsClick(option);
                        }
                      }}
                      disabled={selectedOption && selectedOption !== option}
                    >
                      {option.option === "Hotel" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M22 21H2V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4V9H21V19H22V21ZM17 19H19V11H13V19H15V13H17V19ZM17 9V5H5V19H11V9H17ZM7 11H9V13H7V11ZM7 15H9V17H7V15ZM7 7H9V9H7V7Z"
                            fill="#006BD5"
                          />
                        </svg>
                      )}
                      {option.option === "About Us" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M10.125 15C13.2316 15 15.75 12.4816 15.75 9.375C15.75 6.2684 13.2316 3.75 10.125 3.75C7.0184 3.75 4.5 6.2684 4.5 9.375C4.5 12.4816 7.0184 15 10.125 15Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M2.08124 18.75C3.06679 17.5753 4.29765 16.6308 5.6873 15.9828C7.07695 15.3347 8.59166 14.9989 10.125 14.9989C11.6583 14.9989 13.173 15.3347 14.5627 15.9828C15.9523 16.6308 17.1832 17.5753 18.1687 18.75"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 14.25C21.4534 14.25 22.125 13.5784 22.125 12.75C22.125 11.9216 21.4534 11.25 20.625 11.25C19.7966 11.25 19.125 11.9216 19.125 12.75C19.125 13.5784 19.7966 14.25 20.625 14.25Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 11.25V10.125"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.3219 12L18.3563 11.4375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.3219 13.5L18.3563 14.0625"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 14.25V15.375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.9281 13.5L22.8938 14.0625"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.9281 12L22.8938 11.4375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option === "Booking Details" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M6.75 20.25H20.25"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.51251 7.5H4.12501L6.00001 9.75H9.00001L7.82814 6.23438C7.79108 6.12172 7.78126 6.00188 7.79949 5.8847C7.81773 5.76751 7.86349 5.65632 7.93303 5.56025C8.00256 5.46418 8.09389 5.38597 8.19951 5.33204C8.30514 5.2781 8.42204 5.24999 8.54064 5.25H10.125L14.25 9.75H19.5C20.2957 9.75 21.0587 10.0661 21.6213 10.6287C22.1839 11.1913 22.5 11.9544 22.5 12.75V15H5.98126C5.33695 14.999 4.71008 14.7906 4.19341 14.4057C3.67675 14.0207 3.29775 13.4796 3.11251 12.8625L1.79064 8.46563C1.75693 8.35335 1.75002 8.23473 1.77045 8.11929C1.79088 8.00386 1.83808 7.89482 1.90827 7.80093C1.97846 7.70703 2.06969 7.6309 2.17462 7.57864C2.27956 7.52638 2.39529 7.49945 2.51251 7.5V7.5Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option === "Contact Us" && (
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M9.17187 11.7C9.94364 13.2938 11.2324 14.5792 12.8281 15.3469C12.9458 15.4026 13.076 15.4268 13.2059 15.4169C13.3358 15.407 13.4608 15.3635 13.5687 15.2907L15.9125 13.725C16.016 13.6548 16.1357 13.6119 16.2603 13.6005C16.3849 13.589 16.5104 13.6093 16.625 13.6594L21.0125 15.5438C21.1625 15.6062 21.2877 15.7162 21.369 15.8568C21.4504 15.9974 21.4832 16.1608 21.4625 16.3219C21.3234 17.4073 20.7937 18.4048 19.9723 19.1278C19.1509 19.8508 18.0943 20.2498 17 20.25C13.6185 20.25 10.3755 18.9067 7.98439 16.5156C5.5933 14.1246 4.25 10.8815 4.25 7.50003C4.25025 6.40578 4.6492 5.34911 5.37221 4.52774C6.09522 3.70637 7.09274 3.17659 8.17812 3.03753C8.33922 3.01684 8.50266 3.04967 8.64326 3.13099C8.78386 3.2123 8.89384 3.33758 8.95625 3.48753L10.8406 7.88441C10.8896 7.99723 10.9101 8.12038 10.9003 8.24299C10.8905 8.36561 10.8507 8.48393 10.7844 8.58753L9.21875 10.9688C9.14905 11.0765 9.10814 11.2003 9.09993 11.3283C9.09172 11.4563 9.11649 11.5843 9.17187 11.7V11.7Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.25 4.5H20V8.25"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.5 9L20 4.5"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option}
                    </button>
                  ) &&
                  !about &&
                  !hotelClicked && (
                    <button
                      key={option.id}
                      className="option"
                      onClick={() => {
                        if (option?.option === "Hotel") {
                          handleHotelClick(option);
                        }
                        if (option?.option === "About Us") {
                          handleAboutUsClick(option);
                        }
                        if (option?.option === "Booking Details") {
                          handleBookingClick(option);
                        }
                        if (option?.option === "Contact Us") {
                          handleContactUsClick(option);
                        }
                      }}
                      disabled={selectedOption && selectedOption !== option}
                    >
                      {option.option === "Hotel" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M22 21H2V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4V9H21V19H22V21ZM17 19H19V11H13V19H15V13H17V19ZM17 9V5H5V19H11V9H17ZM7 11H9V13H7V11ZM7 15H9V17H7V15ZM7 7H9V9H7V7Z"
                            fill="#006BD5"
                          />
                        </svg>
                      )}
                      {option.option === "About Us" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M10.125 15C13.2316 15 15.75 12.4816 15.75 9.375C15.75 6.2684 13.2316 3.75 10.125 3.75C7.0184 3.75 4.5 6.2684 4.5 9.375C4.5 12.4816 7.0184 15 10.125 15Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M2.08124 18.75C3.06679 17.5753 4.29765 16.6308 5.6873 15.9828C7.07695 15.3347 8.59166 14.9989 10.125 14.9989C11.6583 14.9989 13.173 15.3347 14.5627 15.9828C15.9523 16.6308 17.1832 17.5753 18.1687 18.75"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 14.25C21.4534 14.25 22.125 13.5784 22.125 12.75C22.125 11.9216 21.4534 11.25 20.625 11.25C19.7966 11.25 19.125 11.9216 19.125 12.75C19.125 13.5784 19.7966 14.25 20.625 14.25Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 11.25V10.125"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.3219 12L18.3563 11.4375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.3219 13.5L18.3563 14.0625"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.625 14.25V15.375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.9281 13.5L22.8938 14.0625"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.9281 12L22.8938 11.4375"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option === "Booking Details" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M6.75 20.25H20.25"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.51251 7.5H4.12501L6.00001 9.75H9.00001L7.82814 6.23438C7.79108 6.12172 7.78126 6.00188 7.79949 5.8847C7.81773 5.76751 7.86349 5.65632 7.93303 5.56025C8.00256 5.46418 8.09389 5.38597 8.19951 5.33204C8.30514 5.2781 8.42204 5.24999 8.54064 5.25H10.125L14.25 9.75H19.5C20.2957 9.75 21.0587 10.0661 21.6213 10.6287C22.1839 11.1913 22.5 11.9544 22.5 12.75V15H5.98126C5.33695 14.999 4.71008 14.7906 4.19341 14.4057C3.67675 14.0207 3.29775 13.4796 3.11251 12.8625L1.79064 8.46563C1.75693 8.35335 1.75002 8.23473 1.77045 8.11929C1.79088 8.00386 1.83808 7.89482 1.90827 7.80093C1.97846 7.70703 2.06969 7.6309 2.17462 7.57864C2.27956 7.52638 2.39529 7.49945 2.51251 7.5V7.5Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option === "Contact Us" && (
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "12px" }}
                        >
                          <path
                            d="M9.17187 11.7C9.94364 13.2938 11.2324 14.5792 12.8281 15.3469C12.9458 15.4026 13.076 15.4268 13.2059 15.4169C13.3358 15.407 13.4608 15.3635 13.5687 15.2907L15.9125 13.725C16.016 13.6548 16.1357 13.6119 16.2603 13.6005C16.3849 13.589 16.5104 13.6093 16.625 13.6594L21.0125 15.5438C21.1625 15.6062 21.2877 15.7162 21.369 15.8568C21.4504 15.9974 21.4832 16.1608 21.4625 16.3219C21.3234 17.4073 20.7937 18.4048 19.9723 19.1278C19.1509 19.8508 18.0943 20.2498 17 20.25C13.6185 20.25 10.3755 18.9067 7.98439 16.5156C5.5933 14.1246 4.25 10.8815 4.25 7.50003C4.25025 6.40578 4.6492 5.34911 5.37221 4.52774C6.09522 3.70637 7.09274 3.17659 8.17812 3.03753C8.33922 3.01684 8.50266 3.04967 8.64326 3.13099C8.78386 3.2123 8.89384 3.33758 8.95625 3.48753L10.8406 7.88441C10.8896 7.99723 10.9101 8.12038 10.9003 8.24299C10.8905 8.36561 10.8507 8.48393 10.7844 8.58753L9.21875 10.9688C9.14905 11.0765 9.10814 11.2003 9.09993 11.3283C9.09172 11.4563 9.11649 11.5843 9.17187 11.7V11.7Z"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.25 4.5H20V8.25"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.5 9L20 4.5"
                            stroke="#006BD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {option.option}
                    </button>
                  )
              )}
            </div>
          )}
          {isTyping && about && (
            <div className="question-div">
              <div className="innr_box">
                <div className="snippet">
                  <div className="stage">
                    <div className="dot-falling"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {contactUs &&
            printSuccessBooking === undefined &&
            contactmesssage !== undefined && (
              <div
                className="question-div"
                style={{ position: "relative", top: "444px" }}
                ref={messagesEndRef}
              >
                <div className="innr_box">
                  <div className="snippet">
                    <div className="stage">
                      <div className="dot-falling"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {!isTyping && about && (
            <div className="question-div">
              <div className="innr_box">
                At OneClick IT Consultancy, we are dedicated to providing the
                best IT services for businesses of all sizes. Whether you're a
                large company or a small business, we've got you covered. Our
                team is made up of experienced and knowledgeable IT
                professionals who are passionate about helping business owners
                achieve their goals and grow their businesses. Our goal is to
                help our clients become more successful by providing them with
                the best technology solutions possible.
              </div>
            </div>
          )}
          {isTyping2 && bookingDetails && (
            <div className="question-div" style={{ marginTop: "-35px" }}>
              <div className="innr_box">
                <div className="snippet">
                  <div className="stage">
                    <div className="dot-falling"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isTyping2 && bookingDetails && questionData && (
            <div className="question-div" style={{ marginTop: "-35px" }}>
              <div className="innr_box">
                {questionData.map((question) => {
                  return <div key={question}>{question.content}</div>;
                })}
              </div>
            </div>
          )}
          {bookingDetails && questionData && detailsBtn && (
            <div className="user-input-value">
              <div className="innr_box">{bookingemail}</div>
            </div>
          )}
          {!bookingDetailsLoader &&
            bookingDetails &&
            questionData &&
            detailsBtn &&
            bookDetailsData.length === 0 && (
              <div className="question-div">
                <div className="innr_box"> {bookingDetailsEmailMsg}</div>
              </div>
            )}
          {bookingDetailsLoader && (
            <div className="question-div" style={{}}>
              <div className="innr_box">
                <div className="snippet">
                  <div className="stage">
                    <div className="dot-falling"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!bookingDetailsLoader &&
          bookingDetails &&
          questionData &&
          detailsBtn ? (
            <div className="question-div">
              {bookDetailsData.map((item) => {
                return item.checkOut < isoDateString ? (
                  ""
                ) : (
                  <div
                    key={item}
                    className="innr_box"
                    style={{
                      padding: "15px",
                      borderRadius: "2%",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      marginBottom: "10px",
                    }}
                  >
                    <h6 className="booking-details-name">Name:</h6>
                    {item.name}
                    <br></br>
                    <h6 className="booking-details-hotel-name">Hotel Name :</h6>
                    {item.hotelName}
                    <br></br>
                    <h6 className="hotel-details-location">Hotel Location :</h6>
                    {item.hotelLocation}
                    <br></br>
                    <h6 className="hotel-details-indate"> Check In Date : </h6>
                    {item.checkIn.substring(0, 10)}
                    <br></br>
                    <h6 className="hotel-details-outdate">
                      {" "}
                      Check Out Date :{" "}
                    </h6>
                    {item.checkOut.substring(0, 10)}
                    <br></br>
                    <h6 className="hotel-details-status">
                      Booking Status :
                    </h6>{" "}
                    {item.bookingStatus}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {contactUs &&
            questionData?.map((question, index) => {
              if (index === currentQuestionContactIndex) {
                setTimeout(() => {
                  // show question content after 3 seconds
                  const questionElement = document.getElementById(
                    `question-${index}`
                  );
                  if (questionElement) {
                    questionElement.style.display = "inline-block";
                  }
                  // hide typing indicator
                  const typingElement = document.getElementById(
                    `typing-${index}`
                  );
                  if (typingElement) {
                    typingElement.style.display = "none";
                  }
                }, 100); // 3 seconds
                return (
                  <React.Fragment key={index}>
                    {
                      <div className="question-div" key={index}>
                        <div
                          id={`question-${index}`}
                          className="innr_box"
                          style={{ display: "none" }}
                        >
                          {question.content}
                        </div>
                        <div id={`typing-${index}`} className="innr_box">
                          <div className="snippet">
                            <div className="stage">
                              <div className="dot-falling"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div
                      className="user-input-value"
                      style={{ display: "none" }}
                    >
                      <div id={`user-input-${index}`} className="innr_box">
                        {userinputarray[index]}
                      </div>
                    </div>
                  </React.Fragment>
                );
              } else if (userinputarray[index]) {
                setTimeout(() => {
                  // show user input value after 3 seconds
                  const userInputElement = document.getElementById(
                    `user-input-${index}`
                  );
                  if (userInputElement) {
                    userInputElement.style.display = "inline-block";
                  }
                }, 100); // 3 seconds
                return (
                  <React.Fragment key={index}>
                    <div className="question-div">
                      <div className="innr_box" ref={messagesEndRef}>
                        {question.content}
                      </div>
                    </div>
                    <div className="user-input-value" ref={messagesEndRef}>
                      <div className="innr_box">{userinputarray[index]}</div>
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            })}
          {displayQuestions &&
            questionData?.map((question, index) => {
              if (index === currentQuestionIndex) {
                setTimeout(() => {
                  // show question content after 3 seconds
                  const questionElement = document.getElementById(
                    `question-${index}`
                  );
                  if (questionElement) {
                    questionElement.style.display = "inline-block";
                  }
                  // hide typing indicator
                  const typingElement = document.getElementById(
                    `typing-${index}`
                  );
                  if (typingElement) {
                    typingElement.style.display = "none";
                  }
                }, 100); // 3 seconds
                return (
                  <React.Fragment key={index}>
                    {hotelClicked && (
                      <div className="question-div" key={index}>
                        <div
                          id={`question-${index}`}
                          className="innr_box"
                          style={{ display: "none" }}
                        >
                          {question.content}
                        </div>
                        <div id={`typing-${index}`} className="innr_box">
                          <div className="snippet">
                            <div className="stage">
                              <div className="dot-falling"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="user-input-value"
                      style={{ display: "none" }}
                    >
                      <div id={`user-input-${index}`} className="innr_box">
                        {userinputarray[index]}
                      </div>
                      {selectedCity && (
                        <div className="innr_box">{selectedCity}</div>
                      )}
                    </div>
                  </React.Fragment>
                );
              } else if (userinputarray[index]) {
                setTimeout(() => {
                  // show user input value after 3 seconds
                  const userInputElement = document.getElementById(
                    `user-input-${index}`
                  );
                  if (userInputElement) {
                    userInputElement.style.display = "inline-block";
                  }
                }, 100); // 3 seconds
                return (
                  <React.Fragment key={index}>
                    <div className="question-div">
                      <div className="innr_box" ref={messagesEndRef}>
                        {question.content}
                      </div>
                    </div>
                    <div className="user-input-value">
                      <div className="innr_box" ref={messagesEndRef}>
                        {userinputarray[index]}
                      </div>
                      {selectedCity && (
                        <div className="innr_box">{selectedCity}</div>
                      )}
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            })}
        </>
      )}
    </>
  );
};
export default HotelDetails;
