import React from "react";
import "../../App.css";
import ReactStars from "react-rating-stars-component";
import "../HotelListing/HotelListings.css";
import { FaStar } from "react-icons/fa";
const HotelListing = ({
  hotels,
  messagesEndRef,
  handlePageChange,
  selectRoom,
  currentPage,
  hotelsPerPage,
  handleHotelPrintClick,
  statusCodeRoom,
  btn,
  hotelLoader,
}) => {
  return (
    <>
      {statusCodeRoom !== 404 && hotels && hotels.length > 0 && (
        <div className="hotel_div">
          <span
            className="span_div"
            ref={messagesEndRef}
            style={selectRoom.length !== 0 ? { display: "none" } : {}}
          >
            Filter by:{""}
          </span>
          <div
            className="option_wrapper"
            style={selectRoom.length !== 0 ? { display: "none" } : {}}
          >
            <select className="select_div" onChange={handlePageChange}>
              <option value="priceAsc">Price: Low to High</option>
              <option value="priceDesc">Price: High to Low</option>
            </select>
          </div>

          <div
            className="option_wrapper"
            style={selectRoom.length !== 0 ? { display: "none" } : {}}
          >
            <select className="select_div" onChange={handlePageChange}>
              <option value="ratingAsc">Ratings: Low to High</option>
              <option value="ratingDesc">Ratings: High to Low</option>
            </select>
          </div>
        </div>
      )}
      {hotels
        .slice((currentPage - 1) * hotelsPerPage, currentPage * hotelsPerPage)
        .map((hotel) => (
          <div
            key={hotel.id}
            style={selectRoom.length !== 0 ? { display: "none" } : {}}
            className="col-md-6"
          >
            <div
              className="card-wrapper"
              onClick={() => {
                handleHotelPrintClick(hotel);
              }}
            >
              <div className="card-img">
                <img src={hotel.thumbnail} className="hotel-img"></img>
              </div>
              <div className="card-info-wrapper">
                <div className="card-info">
                  <span className="card-title-name" title={hotel.name}>
                    {hotel.name}
                  </span>
                </div>

                <div className="ratings-info">
                  <span className="ratings">
                    Ratings{" "}
                    <ReactStars
                      count={parseFloat(hotel.review_rating)}
                      size={16}
                      color="#0069d9"
                      isHalf={true}
                      filledIcon={<FaStar style={{ color: "#0069d9" }} />}
                      activeColor="#0069d9"
                    />
                  </span>
                </div>
                <span className="card-title-price">{`$${Math.round(
                  hotel.total_price
                )}`}</span>
                <div className="card-btn">
                  <button
                    className="select-hotel-btn"
                    onClick={() => {
                      handleHotelPrintClick(hotel);
                    }}
                    disabled={btn ? true : false}
                  >
                    Select Hotel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      {hotelLoader && (
        <div className="loader">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
          <div className="bar4"></div>
          <div className="bar5"></div>
          <div className="bar6"></div>
          <div className="bar7"></div>
          <div className="bar8"></div>
          <div className="bar9"></div>
          <div className="bar10"></div>
          <div className="bar11"></div>
          <div className="bar12"></div>
        </div>
      )}
    </>
  );
};

export default HotelListing;
