import React from "react";
const SecondLoader = ({
  loading,
  messagesEndRef,
  statusCodeRoom,
  statusCodeRoomAvailabe,
}) => {
  if (statusCodeRoom === 400) {
    loading = false;
  }
  if (statusCodeRoomAvailabe === 404) {
    loading = false;
  }
  return (
    <>
      {" "}
      {loading ? (
        <div
          className="question-div"
          ref={messagesEndRef}
          style={{ marginTop: "10px" }}
        >
          <div className="innr_box">
            <div className="snippet">
              <div className="stage">
                <div className="dot-falling"></div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default SecondLoader;
