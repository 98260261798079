import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({
    key: "print",
    storage: localStorage
});
export const print = atom({
  key: 'print',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
