import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "../App.css";
import {
  getHotelList,
  questionListing,
  searchCity,
  intialListing,
  getFilterList, 
  getRoomList,
  confirmRoom,
  userDetails,
  postUserDetails,
  bookingDetailsQuestion,
  getBookingDetails,
  confirmHotelBooking,
  ContactUsQuestions,
  ContactUsDetails,
} from "../Services/Api";
import Header from "../Components/Header/Header";
import HotelDetails from "../Components/HotelDetails/HotelDetails";
import HotelListing from "../Components/HotelListing/HotelListing";
import RoomListing from "../Components/RoomListing/RoomListing";
import UserDetails from "../Components/UserDetails/UserDetails";
import AutoComplete from "../Components/AutoComplete/AutoComplete";
import HotelInput from "../Components/HotelInput/HotelInput";
import UserInput from "../Components/UserInput/UserInput";
import HotelButtons from "../Components/HotelButtons/HotelButtons";
import FirstLoader from "../Components/FirstLoader/FirstLoader";
import SecondLoader from "../Components/SecondLoader/SecondLoader";
import BookingDetailsInput from "../Components/BookingDetailsInput/BookingDetailsInput";
import PaymentLoader from "../Components/PaymentLoader/PaymentLoader";
import SuccessBooking from "../Components/SuccessBooking/SuccessBooking";
import { print } from "../Recoil/atom";
import { useRecoilState } from "recoil";
import ContactUsInput from "../Components/ContactUsInput/ContactUsInput";
import VectorImg from "../Assets/Images/Vector.svg";
import Payment from "../Components/Payment/Payment";
const Chatbot = () => {
  const [userData, setUserData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNo: null,
    address: null,
    city: null,
    postalCode: null,
  });
  const [questionListingData, setQuestionListingData] = useState({
    data: {},
    questionData: undefined,
    displayQuestions: false,
    userQuestion: undefined,
  });
  const [questionIndex, setQuestionIndex] = useState({
    currentQuestionIndex: 0,
    currentQuestionContactIndex: 0,
    currentUserQuestionIndex: 0,
  });

  const [inputs, setInputs] = useState({
    userInput: "",
    userinputarray: [],
    userDetailsinputarray: [],
    showInput: false,
  });
  const [autoSearch, setAutoSearch] = useState({
    searchData: [],
    cityID: undefined,
  });
  const [hotelData, setHotelData] = useState({
    selectedCity: null,
    minDate: new Date().toISOString().slice(0, 10),
    Selected: false,
    sendData: false,
    checkInDate: null,
    checkOutDate: null,
    numPersons: null,
    numChild: null,
    numRooms: null,
    hotels: [],
    currentPage: 1,
    hotelsPerPage: 4,
  });
  const [contactUsData, setContactUsData] = useState({
    contactName: null,
    contactEmail: null,
    contactNumber: null,
    contactmesssage: undefined,
    sendContactUsDetails: false,
  });
  const [hotelListingData, setHotelListingData] = useState({
    selectedHotelRating: null,
    selectedHotelName: null,
    selectedImage: null,
    selectedPrice: null,
    selectedPpn: null,
    statusCodeHotel: undefined,
    btn: false,
    responseMessage: undefined,
    selectedHotelImage: null,
  });
  const [roomListingData, setRoomListingData] = useState({
    selectRoom: [],
    RoomImg: [],
    bookRoom: false,
    selectedRoomType: "",
    selectedRoomPrice: null,
    selectedRoomPPN: null,
    roomImagesOpen: false,
    bookbtn: false,
    statusCodeRoom: undefined,
    statusCodeRoomAvailabe: undefined,
    responseSuccess: undefined,
    selectedRoomTitle: null,
  });
  const [ismessageLoading, setIsmessageLoading] = useState({
    isTyping: true,
    isTyping2: true,
    isTyping3: true,
    isTyping4: true,
  });
  const [transactionDetails, setTransactionDetails] = useState({
    clientSecret: "",
    transactionid: "",
    bookingId: undefined,
  });
  const [messageLoaders, setMessageLoader] = useState({
    loading: false,
    loader: false,
    hotelLoader: false,
    bookingDetailsLoader: false,
  });
  const [optionClick, setOptionClick] = useState({
    hotelClicked: false,
    about: false,
    bookingDetails: false,
    contactUs: false,
    detailsBtn: false,
    selectedOption: null,
  });
  const [confirmBooking, setConfirmBooking] = useState({
    showConfirmMsg: false,
    showSuccessMsg: false,
    printSuccessBooking: undefined,
    statusCodeBooking: undefined,
    confirmBookingStatus: null,
  });
  const [bookingDetailData, setBookingDetailData] = useState({
    bookingemail: undefined,
    bookingDetailsEmailMsg: undefined,
    bookDetailsData: [],
  });
  const messagesEndRef = useRef(null);
  const [sortByPrice, setSortByPrice] = useState();
  const [height, setHeight] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [autoFocus, setAutoFocus] = useState(false);
  const [printConfirm, setPrintConfirm] = useRecoilState(print);
  const [showLink, SetshowLink] = useState(false);
  const [logId, setLogId] = useState(null);
  const [sendDatas, setSendDatas] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsmessageLoading((prevState) => ({ ...prevState, isTyping: false }));
    }, 100);
    return () => clearTimeout(timer);
  }, [ismessageLoading.isTyping]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsmessageLoading((prevState) => ({ ...prevState, isTyping2: false }));
    }, 100);
    return () => clearTimeout(timer);
  }, [ismessageLoading.isTyping2]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsmessageLoading((prevState) => ({ ...prevState, isTyping3: false }));
    }, 100);
    return () => clearTimeout(timer);
  }, [ismessageLoading.isTyping3]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsmessageLoading((prevState) => ({ ...prevState, isTyping4: false }));
    }, 100);
    return () => clearTimeout(timer);
  }, [ismessageLoading.isTyping4]);
  // Api
  useEffect(() => {
    intialListing()
      .then((response) => {
        setQuestionListingData((prevState) => ({
          ...prevState,
          data: response.data,
        }));
        localStorage.setItem("print", false);
        setPrintConfirm(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleHotelClick = (option) => {
    setOptionClick((prevState) => ({ ...prevState, selectedOption: option }));
    questionListing()
      .then((response) => {
        setQuestionListingData((prevState) => ({
          ...prevState,
          questionData: response.data.question,
        }));
        setLogId(response.data.logId);
        setQuestionListingData((prevState) => ({
          ...prevState,
          displayQuestions: true,
        }));
        setOptionClick((prevState) => ({
          ...prevState,
          hotelClicked: true,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleBookingClick = (option) => {
    setOptionClick((prevState) => ({
      ...prevState,
      bookingDetails: true,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping2: true }));

    setOptionClick((prevState) => ({ ...prevState, selectedOption: option }));
    bookingDetailsQuestion().then((response) => {
      setQuestionListingData((prevState) => ({
        ...prevState,
        questionData: response.data.question,
      }));
    });
  };
  const handleAboutUsClick = (option) => {
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping: true }));
    setOptionClick((prevState) => ({
      ...prevState,
      about: true,
    }));
    setOptionClick((prevState) => ({ ...prevState, selectedOption: option }));
  };
  const handleContactUsClick = (option) => {
    setOptionClick((prevState) => ({
      ...prevState,
      contactUs: true,
    }));
    setOptionClick((prevState) => ({ ...prevState, selectedOption: option }));
    ContactUsQuestions().then((response) => {
      setQuestionListingData((prevState) => ({
        ...prevState,
        questionData: response.data.question,
      }));
    });
  };
  useEffect(() => {
    if (optionClick.hotelClicked) {
      if (questionIndex.currentQuestionIndex === 0) {
        if (inputs.userInput.length > 2) {
          const search = setTimeout(() => {
            searchCity(inputs.userInput).then((response) => {
              setAutoSearch((prevState) => ({
                ...prevState,
                searchData: response.data.data,
              }));
            });
          }, 1000);
          return () => clearTimeout(search);
        } else {
          setAutoSearch((prevState) => ({
            ...prevState,
            searchData: [],
          }));
        }
      }
    }
  }, [inputs.userInput]);
  useEffect(() => {
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: false,
    }));
    if (
      autoSearch.cityID &&
      hotelData.checkInDate &&
      hotelData.checkOutDate &&
      hotelData.numRooms &&
      hotelData.numPersons &&
      hotelData.numChild &&
      sortByPrice
    ) {
      setMessageLoader((prevState) => ({
        ...prevState,
        hotelLoader: true,
      }));
      getFilterList(
        autoSearch.cityID,
        hotelData.checkInDate,
        hotelData.checkOutDate,
        hotelData.numRooms,
        hotelData.numPersons,
        hotelData.numChild,
        sortByPrice
      )
        .then((response) => {
          setHotelData((prevState) => ({
            ...prevState,
            hotels: response.data.data,
          }));
          setMessageLoader((prevState) => ({
            ...prevState,
            hotelLoader: false,
          }));
          setHeight(true);
        })
        .catch((error) => {
          console.log(error);
          setMessageLoader((prevState) => ({
            ...prevState,
            loader: false,
          }));
        })
        .finally(() =>
          setHotelData((prevState) => ({ ...prevState, sendData: false }))
        );
    }
    return () => clearTimeout();
  }, [sortByPrice]);
  useEffect(() => {
    if (hotelData.sendData) {
      setMessageLoader((prevState) => ({
        ...prevState,
        loader: true,
      }));
      getHotelList(
        autoSearch.cityID,
        hotelData.checkInDate,
        hotelData.checkOutDate,
        hotelData.numRooms,
        hotelData.numPersons,
        hotelData.numChild,
        logId
      )
        .then((response) => {
          if (response.data.data) {
            setHotelData((prevState) => ({
              ...prevState,
              hotels: response.data.data,
            }));
            setMessageLoader((prevState) => ({
              ...prevState,
              loader: false,
            }));
          }
          if (response.data) {
            setHotelListingData((prevState) => ({
              ...prevState,
              statusCodeHotel: response.data.status,
            }));
            setMessageLoader((prevState) => ({
              ...prevState,
              loader: false,
            }));
          }
          setHeight(true);
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            setHotelListingData((prevState) => ({
              ...prevState,
              statusCodeHotel: error.response.status,
            }));
          } else {
            // setStatusCodeHotel("Unknown error");
          }
        });
    }
  }, [hotelData.numRooms]);
  useEffect(() => {
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping4: true }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: true,
    }));
    if (sendDatas) {
      SetshowLink(true);
      postUserDetails(
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.phoneNo,
        userData.address,
        userData.city,
        userData.postalCode,
        roomListingData.selectedRoomPPN,
        hotelListingData.selectedHotelImage,
        roomListingData.selectedRoomTitle, 
        roomListingData.RoomImg[3]
      )
        .then((response) => {
          setMessageLoader((prevState) => ({
            ...prevState,
            loader: false,
          }));
          setHotelListingData((prevState) => ({
            ...prevState,
            responseMessage: response.status,
          }));
          setTransactionDetails((prevState) => ({
            ...prevState,
            transactionid: response.data.data.transactionId,
          }));
          setTransactionDetails((prevState) => ({
            ...prevState,
            clientSecret: response.data.data.clientSecret,
          }));
          setTransactionDetails((prevState) => ({
            ...prevState,
            bookingId: response.data.bookingId,
          }));
          if (response.data) {
            setHotelListingData((prevState) => ({
              ...prevState,
              responseMessage: response.status,
            }));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setHotelData((prevState) => ({ ...prevState, sendData: false }));
        });
    }
  }, [sendDatas]);
  useEffect(() => {
    if (hotelListingData.selectedPpn) {
      setMessageLoader((prevState) => ({
        ...prevState,
        loading: true,
      }));
      getRoomList(hotelListingData.selectedPpn)
        .then((response) => {
          setMessageLoader((prevState) => ({
            ...prevState,
            loading: false,
          }));
          if (response.data.data) {
            setRoomListingData((prevState) => ({
              ...prevState,
              selectRoom: response.data.data,
            }));
          }
          if (response.data.roomData) {
            setRoomListingData((prevState) => ({
              ...prevState,
              RoomImg: response.data.roomData,
            }));
          }
          if (response.data) {
            setRoomListingData((prevState) => ({
              ...prevState,
              statusCodeRoom: response.data.status,
            }));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            setRoomListingData((prevState) => ({
              ...prevState,
              statusCodeRoom: error.response.status,
            }));
          } else {
            // setStatusCodeRoom("Unknown error");
          }
        });
    }
  }, [hotelListingData.selectedPpn]);
  useEffect(() => {
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: true,
    }));
    if (roomListingData.bookbtn) {
      confirmRoom(roomListingData.selectedRoomPPN)
        .then((response) => {
          setRoomListingData((prevState) => ({
            ...prevState,
            responseSuccess: response.data.status,
          }));
          setMessageLoader((prevState) => ({
            ...prevState,
            loader: false,
          }));
          if (response.data) {
            setRoomListingData((prevState) => ({
              ...prevState,
              statusCodeRoomAvailabe: response.data.status,
            }));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            setRoomListingData((prevState) => ({
              ...prevState,
              statusCodeRoomAvailabe: error.response.status,
            }));
            setMessageLoader((prevState) => ({
              ...prevState,
              loader: false,
            }));
          } else {
            // setStatusCodeRoomAvailable("Unknown error");
            setMessageLoader((prevState) => ({
              ...prevState,
              loader: false,
            }));
          }
        });
    }
  }, [roomListingData.bookbtn]);
  useEffect(() => {
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: true,
    }));
    userDetails()
      .then((response) => {
        setQuestionListingData((prevState) => ({
          ...prevState,
          userQuestion: response.data.question,
        }));
        setMessageLoader((prevState) => ({
          ...prevState,
          loader: false,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [roomListingData.responseSuccess]);
  useEffect(() => {
    if (optionClick.detailsBtn) {
      setMessageLoader((prevState) => ({
        ...prevState,
        bookingDetailsLoader: true,
      }));
      getBookingDetails(bookingDetailData.bookingemail)
        .then((response) => {
          if (response.data.data) {
            setBookingDetailData((prevState) => ({
              ...prevState,
              bookDetailsData: response.data.data,
            }));
            setMessageLoader((prevState) => ({
              ...prevState,
              bookingDetailsLoader: false,
            }));
          } else {
            setBookingDetailData((prevState) => ({
              ...prevState,
              bookingDetailsEmailMsg: response.data.message,
            }));
            setMessageLoader((prevState) => ({
              ...prevState,
              bookingDetailsLoader: false,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [optionClick.detailsBtn]);
  useEffect(() => {
    if (printConfirm) {
      setConfirmBooking((prevState) => ({
        ...prevState,
        showConfirmMsg: true,
      }));
      confirmHotelBooking(
        transactionDetails.transactionid,
        transactionDetails.bookingId
      )
        .then((response) => {
          setIsmessageLoading((prevState) => ({
            ...prevState,
            isTyping3: false,
          }));
          setConfirmBooking((prevState) => ({
            ...prevState,
            printSuccessBooking: response.data.message,
          }));
          setConfirmBooking((prevState) => ({
            ...prevState,
            confirmBookingStatus: response.data.status,
          }));
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            setConfirmBooking((prevState) => ({
              ...prevState,
              confirmBookingStatus: error.response.status,
            }));
          } else {
            // setConfirmBookingStatus("Unknown error");
          }
        });
    }
  }, [printConfirm]);
  useEffect(() => {
    if (contactUsData.sendContactUsDetails) {
      ContactUsDetails(
        contactUsData.contactName,
        contactUsData.contactEmail,
        contactUsData.contactNumber,
        contactUsData.contactmesssage
      ).then((response) => {
        setConfirmBooking((prevState) => ({
          ...prevState,
          printSuccessBooking: response.data.message,
        }));
      });
    }
  }, [contactUsData.contactmesssage]);
  //
  const backButton = () => {
    setLogId(null);
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomTitle: null,
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentUserQuestionIndex: 0,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      clientSecret: null,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      transactionid: null,
    }));
    setInputs((prevState) => ({
      ...prevState,
      userinputarray: [],
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionIndex: 0,
    }));
    setInputs((prevState) => ({
      ...prevState,
      userInput: "",
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loading: false,
    }));
    setAutoSearch((prevState) => ({
      ...prevState,
      searchData: [],
    }));
    setHeight(false);
    setHotelData((prevState) => ({
      ...prevState,
      minDate: new Date().toISOString().slice(0, 10),
    }));
    setHotelData((prevState) => ({ ...prevState, checkInDate: null }));
    setHotelData((prevState) => ({ ...prevState, checkOutDate: null }));
    setHotelData((prevState) => ({ ...prevState, numPersons: null }));
    setHotelData((prevState) => ({ ...prevState, numChild: null }));
    setHotelData((prevState) => ({ ...prevState, numRooms: null }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setHotelData((prevState) => ({ ...prevState, sendData: false }));
    setSendDatas(false);
    setMessageLoader((prevState) => ({
      ...prevState,
      hotelLoader: false,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelRating: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelName: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelPrice: null,
    }));
    setHotelListingData((prevState) => ({ ...prevState, selectedPpn: null }));
    setInputs((prevState) => ({
      ...prevState,
      showInput: false,
    }));
    setHotelData((prevState) => ({ ...prevState, hotels: [] }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectRoom: [],
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      btn: false,
    }));
    setRoomListingData((prevState) => ({ ...prevState, bookRoom: false }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPrice: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      bookbtn: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPPN: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      responseSuccess: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoomAvailabe: null,
    }));
    setQuestionListingData((prevState) => ({
      ...prevState,
      userQuestion: null,
    }));
    setRoomListingData((prevState) => ({ ...prevState, selectedRoomType: "" }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoom: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      detailsBtn: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      bookingDetailsLoader: false,
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookDetailsData: [],
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookingDetailsEmailMsg: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      showConfirmMsg: false,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping3: true }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      printSuccessBooking: null,
    }));
    setPrintConfirm(false);
    setUserData((prevState) => ({ ...prevState, firstName: null }));
    setUserData((prevState) => ({ ...prevState, lastName: null }));
    setUserData((prevState) => ({ ...prevState, email: null }));
    setUserData((prevState) => ({ ...prevState, phoneNo: null }));
    setUserData((prevState) => ({ ...prevState, address: null }));
    setUserData((prevState) => ({ ...prevState, city: null }));
    setUserData((prevState) => ({ ...prevState, postalCode: null }));
    setInputs((prevState) => ({
      ...prevState,
      userInput: "",
    }));
    setInputs((prevState) => ({
      ...prevState,
      userDetailsinputarray: [],
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionContactIndex: 0,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      bookingDetails: false,
    }));
    setOptionClick((prevState) => ({ ...prevState, selectedOption: null }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping2: false }));
    setQuestionListingData((prevState) => ({
      ...prevState,
      questionData: null,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      detailsBtn: false,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      about: false,
    }));
    setOptionClick((prevState) => ({ ...prevState, selectedOption: null }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping: false }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelRating: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelName: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelImage: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelPrice: null,
    }));
    setHotelListingData((prevState) => ({ ...prevState, selectedPpn: null }));
    setInputs((prevState) => ({
      ...prevState,
      showInput: false,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      btn: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoom: null,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loading: false,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      hotelClicked: false,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      bookingId: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      roomImagesOpen: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoomAvailabe: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      statusCodeBooking: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      confirmBookingStatus: null,
    }));
    setHotelData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setRoomListingData((prevState) => ({ ...prevState, RoomImg: [] }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionContactIndex: 0,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      contactUs: false,
    }));
    setContactUsData((prevState) => ({ ...prevState, contactName: null }));
    setContactUsData((prevState) => ({ ...prevState, contactEmail: null }));
    setContactUsData((prevState) => ({ ...prevState, contactNumber: null }));
    setContactUsData((prevState) => ({ ...prevState, contactmesssage: null }));
    setContactUsData((prevState) => ({
      ...prevState,
      sendContactUsDetails: false,
    }));
  };
  const BookAgainButton = () => {
    setLogId(null);
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomTitle: null,
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentUserQuestionIndex: 0,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      clientSecret: null,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      transactionid: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      roomImagesOpen: false,
    }));
    setInputs((prevState) => ({
      ...prevState,
      userinputarray: [],
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionIndex: 0,
    }));
    setInputs((prevState) => ({
      ...prevState,
      userInput: "",
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loading: false,
    }));
    setAutoSearch((prevState) => ({
      ...prevState,
      searchData: [],
    }));
    setHeight(false);
    setHotelData((prevState) => ({
      ...prevState,
      minDate: new Date().toISOString().slice(0, 10),
    }));
    setHotelData((prevState) => ({ ...prevState, checkInDate: null }));
    setHotelData((prevState) => ({ ...prevState, checkOutDate: null }));
    setHotelData((prevState) => ({ ...prevState, numPersons: null }));
    setHotelData((prevState) => ({ ...prevState, numChild: null }));
    setHotelData((prevState) => ({ ...prevState, numRooms: null }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setHotelData((prevState) => ({ ...prevState, sendData: false }));
    setSendDatas(false);
    setMessageLoader((prevState) => ({
      ...prevState,
      hotelLoader: false,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelRating: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelName: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelPrice: null,
    }));
    setHotelListingData((prevState) => ({ ...prevState, selectedPpn: null }));
    setInputs((prevState) => ({
      ...prevState,
      showInput: false,
    }));
    setHotelData((prevState) => ({
      ...prevState,
      hotels: [],
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectRoom: [],
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      btn: false,
    }));
    setRoomListingData((prevState) => ({ ...prevState, bookRoom: false }));

    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPrice: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      bookbtn: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPPN: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      responseSuccess: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoomAvailabe: null,
    }));
    setQuestionListingData((prevState) => ({
      ...prevState,
      userQuestion: null,
    }));
    setRoomListingData((prevState) => ({ ...prevState, selectedRoomType: "" }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoom: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      statusCodeHotel: null,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      detailsBtn: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      bookingDetailsLoader: false,
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookDetailsData: [],
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookingDetailsEmailMsg: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      showConfirmMsg: false,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping3: true }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      printSuccessBooking: null,
    }));
    setPrintConfirm(false);
    setUserData((prevState) => ({ ...prevState, firstName: null }));
    setUserData((prevState) => ({ ...prevState, lastName: null }));
    setUserData((prevState) => ({ ...prevState, email: null }));
    setUserData((prevState) => ({ ...prevState, phoneNo: null }));
    setUserData((prevState) => ({ ...prevState, address: null }));
    setUserData((prevState) => ({ ...prevState, city: null }));
    setUserData((prevState) => ({ ...prevState, postalCode: null }));
    setInputs((prevState) => ({
      ...prevState,
      userInput: "",
    }));
    setInputs((prevState) => ({
      ...prevState,
      userDetailsinputarray: [],
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionContactIndex: 0,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      bookingDetails: false,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping2: false }));
    setQuestionListingData((prevState) => ({
      ...prevState,
      questionData: null,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      detailsBtn: false,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      about: false,
    }));
    setOptionClick((prevState) => ({ ...prevState, selectedOption: null }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping: false }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelRating: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelName: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelImage: null,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelPrice: null,
    }));
    setHotelListingData((prevState) => ({ ...prevState, selectedPpn: null }));
    setInputs((prevState) => ({
      ...prevState,
      showInput: false,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      btn: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoom: null,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loader: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      loading: false,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      hotelClicked: false,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping3: false }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      printSuccessBooking: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      showConfirmMsg: false,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      bookingDetailsLoader: false,
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookDetailsData: [],
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      bookingDetailsLoader: false,
    }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookingDetailsEmailMsg: null,
    }));
    setMessageLoader((prevState) => ({
      ...prevState,
      bookingDetailsLoader: false,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoom: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      responseSuccess: null,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoomAvailabe: null,
    }));
    localStorage.setItem("print", false);
    localStorage.setItem("transactionid", null);
    setHotelListingData((prevState) => ({
      ...prevState,
      responseMessage: null,
    }));
    setIsmessageLoading((prevState) => ({ ...prevState, isTyping4: true }));
    setBookingDetailData((prevState) => ({
      ...prevState,
      bookingemail: null,
    }));
    setTransactionDetails((prevState) => ({
      ...prevState,
      bookingId: null,
    }));
    SetshowLink(false);
    setRoomListingData((prevState) => ({
      ...prevState,
      statusCodeRoomAvailabe: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      statusCodeBooking: null,
    }));
    setConfirmBooking((prevState) => ({
      ...prevState,
      confirmBookingStatus: null,
    }));
    setRoomListingData((prevState) => ({ ...prevState, RoomImg: [] }));
    setHotelData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionContactIndex: 0,
    }));
    setOptionClick((prevState) => ({
      ...prevState,
      contactUs: false,
    }));
    setContactUsData((prevState) => ({ ...prevState, contactName: null }));
    setContactUsData((prevState) => ({ ...prevState, contactEmail: null }));
    setContactUsData((prevState) => ({ ...prevState, contactNumber: null }));
    setContactUsData((prevState) => ({ ...prevState, contactmesssage: null }));
    setContactUsData((prevState) => ({
      ...prevState,
      sendContactUsDetails: false,
    }));
  };
  useEffect(() => {
    if (inputs.userinputarray.length === 2) {
      setHotelData((prevState) => ({
        ...prevState,
        checkInDate: inputs.userinputarray[1],
      }));
    }
    if (inputs.userinputarray.length === 3) {
      setHotelData((prevState) => ({
        ...prevState,
        checkOutDate: inputs.userinputarray[2],
      }));
    }
    if (inputs.userinputarray.length === 4) {
      setHotelData((prevState) => ({
        ...prevState,
        numPersons: inputs.userinputarray[3],
      }));
    }
    if (inputs.userinputarray.length === 5) {
      setHotelData((prevState) => ({
        ...prevState,
        numChild: inputs.userinputarray[4],
      }));
    }
    if (inputs.userinputarray.length === 6) {
      setHotelData((prevState) => ({
        ...prevState,
        numRooms: inputs.userinputarray[5],
      }));
    }
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [inputs.userinputarray]);
  useEffect(() => {
    if (optionClick.contactUs) {
      setContactUsData((prevState) => ({
        ...prevState,
        contactName: inputs.userinputarray[0],
      }));
      setContactUsData((prevState) => ({
        ...prevState,
        contactEmail: inputs.userinputarray[1],
      }));
      setContactUsData((prevState) => ({
        ...prevState,
        contactNumber: inputs.userinputarray[2],
      }));
      setContactUsData((prevState) => ({
        ...prevState,
        contactmesssage: inputs.userinputarray[3],
      }));
    }
  }, [inputs.userinputarray]);
  useEffect(() => {
    if (inputs.userDetailsinputarray.length > 1) {
      setUserData((prevState) => ({
        ...prevState,
        firstName: inputs.userDetailsinputarray[1],
      }));
    }
    if (inputs.userDetailsinputarray.length > 2) {
      setUserData((prevState) => ({
        ...prevState,
        lastName: inputs.userDetailsinputarray[2],
      }));
    }
    if (inputs.userDetailsinputarray.length > 3) {
      setUserData((prevState) => ({
        ...prevState,
        email: inputs.userDetailsinputarray[3],
      }));
    }
    if (inputs.userDetailsinputarray.length > 4) {
      setUserData((prevState) => ({
        ...prevState,
        phoneNo: inputs.userDetailsinputarray[4],
      }));
    }
    if (inputs.userDetailsinputarray.length > 5) {
      setUserData((prevState) => ({
        ...prevState,
        address: inputs.userDetailsinputarray[5],
      }));
    }
    if (inputs.userDetailsinputarray.length > 6) {
      setUserData((prevState) => ({
        ...prevState,
        city: inputs.userDetailsinputarray[6],
      }));
    }
    if (inputs.userDetailsinputarray.length > 7) {
      setUserData((prevState) => ({
        ...prevState,
        postalCode: inputs.userDetailsinputarray[7],
      }));
      setSendDatas(true);
    }
  }, [inputs.userDetailsinputarray]);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [
    hotelData.hotels,
    questionIndex.currentUserQuestionIndex,
    questionIndex.currentQuestionIndex,
    roomListingData.selectRoom,
    messageLoaders.loader,
    messageLoaders.loading,
    confirmBooking.printSuccessBooking,
    roomListingData.statusCodeRoom,
    confirmBooking.confirmBookingStatus,
    roomListingData.roomImagesOpen,
    roomListingData.RoomImg,
    questionListingData.userQuestion,
    hotelListingData.responseMessage,
    contactUsData.contactmesssage,
  ]);
  const checkboxes = document.querySelectorAll(
    'input[type="checkbox"][name="roomType"]'
  );
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      if (checkbox.checked) {
        checkboxes.forEach((cb) => {
          if (cb !== checkbox) {
            cb.checked = false;
          }
        });
      }
    });
  });

  const handleUserDetailsInput = (answer) => {
    if (answer.trim().length > 0) {
      setInputs((prevState) => ({
        ...prevState,
        userDetailsinputarray: [
          ...inputs.userDetailsinputarray.slice(
            0,
            questionIndex.currentUserQuestionIndex
          ),
          answer,
        ],
      }));
      setQuestionIndex((prevState) => ({
        ...prevState,
        currentUserQuestionIndex: questionIndex.currentUserQuestionIndex + 1,
      }));
      setInputs((prevState) => ({
        ...prevState,
        userInput: "",
      }));
    }
  };

  const handleHotelPrintClick = (hotel) => {
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelRating: hotel.review_rating,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelName: hotel.name,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelImage: "https:" + hotel.thumbnail,
    }));
    let price = Math.round(hotel.total_price);
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedHotelPrice: price,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      selectedPpn: hotel.ppn_bundle,
    }));
    setInputs((prevState) => ({
      ...prevState,
      showInput: true,
    }));
    setHotelListingData((prevState) => ({
      ...prevState,
      btn: true,
    }));
  };
  const handleRoomClick = (room) => {
    let price = Math.round(room.total_price);
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPrice: price,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomTitle: room.title,
    }));
  };
  const handleUserInput = (answer) => {
    if (answer.trim().length > 0) {
      if (questionIndex.currentQuestionIndex === 1) {
        const selectedDate = new Date(answer);
        setSelectedDate(selectedDate);
        setHotelData((prevState) => ({ ...prevState, minDate: answer }));
        setHotelData((prevState) => ({ ...prevState, Selected: true }));
      }
      setInputs((prevState) => ({
        ...prevState,
        userinputarray: [
          ...inputs.userinputarray.slice(0, questionIndex.currentQuestionIndex),
          answer,
        ],
      }));

      setQuestionIndex((prevState) => ({
        ...prevState,
        currentQuestionIndex: questionIndex.currentQuestionIndex + 1,
      }));
      setInputs((prevState) => ({
        ...prevState,
        userInput: "",
      }));
      if (questionIndex.currentQuestionIndex === 5) {
        setHotelData((prevState) => ({ ...prevState, sendData: true }));
      }
    }
  };
  const handleUserContactInput = (answer) => {
    setInputs((prevState) => ({
      ...prevState,
      userinputarray: [
        ...inputs.userinputarray.slice(
          0,
          questionIndex.currentQuestionContactIndex
        ),
        answer,
      ],
    }));
    setQuestionIndex((prevState) => ({
      ...prevState,
      currentQuestionContactIndex:
        questionIndex.currentQuestionContactIndex + 1,
    }));
    setInputs((prevState) => ({
      ...prevState,
      userInput: "",
    }));
    if (questionIndex.currentQuestionContactIndex === 3) {
      setContactUsData((prevState) => ({
        ...prevState,
        sendContactUsDetails: true,
      }));
    }
  };

  const handleRoomTypeChange = (event) => {
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomType: event.target.value,
    }));
    setRoomListingData((prevState) => ({
      ...prevState,
      selectedRoomPPN: event.target.getAttribute("ppn_bundle"),
    }));
    setRoomListingData((prevState) => ({ ...prevState, bookRoom: null }));
  };
  function handleCitySelection(city) {
    setInputs((prevState) => ({
      ...prevState,
      userInput: city,
    }));
    handleUserInput(city);
    setHotelData((prevState) => ({ ...prevState, Selected: true }));
  }
  const handleNextPage = () => {
    setHotelData((prevState) => ({
      ...prevState,
      currentPage: hotelData.currentPage + 1,
    }));
  };
  const handlePrevPage = () => {
    setHotelData((prevState) => ({
      ...prevState,
      currentPage: hotelData.currentPage - 1,
    }));
  };
  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };
  const handlePageChange = (event) => {
    setHotelData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setSortByPrice(event.target.value);
  };

  return (
    <div className="chatbot" id="travelChatbot">
      <div
        className={`chatbot-container open`}
        style={{ backgroundImage: { VectorImg } }}
      >
        <Header
          backButton={backButton}
          printConfirm={printConfirm}
          responseMessage={hotelListingData.responseMessage}
          currentQuestionIndex={questionIndex.currentQuestionIndex}
          about={optionClick.about}
          bookingDetails={optionClick.bookingDetails}
          setIsmessageLoading={setIsmessageLoading}
          setIsTyping2={ismessageLoading.isTyping2}
          currentUserQuestionIndex={questionIndex.currentUserQuestionIndex}
          contactUs={optionClick.contactUs}
          bookbtn={roomListingData.bookbtn}
          currentQuestionContactIndex={
            questionIndex.currentQuestionContactIndex
          }
          setOptionClick={setOptionClick}
          hotelClicked={optionClick.hotelClicked}
        />
        <div
          className={
            height && !roomListingData.bookbtn
              ? "chatbot-messages-1"
              : `chatbot-messages${hotelListingData.responseMessage === 200 ? "-1" : ""
              }${confirmBooking.printSuccessBooking ? " hidden" : ""}`
          }
        >
          <HotelDetails
            data={questionListingData.data}
            hotelClicked={optionClick.hotelClicked}
            questionData={questionListingData.questionData}
            handleHotelClick={handleHotelClick}
            displayQuestions={questionListingData.displayQuestions}
            currentQuestionIndex={questionIndex.currentQuestionIndex}
            userinputarray={inputs.userinputarray}
            selectedCity={hotelData.selectedCity}
            messagesEndRef={messagesEndRef}
            handleAboutUsClick={handleAboutUsClick}
            selectedOption={optionClick.selectedOption}
            about={optionClick.about}
            handleBookingClick={handleBookingClick}
            bookingDetails={optionClick.bookingDetails}
            isTyping={ismessageLoading.isTyping}
            isTyping2={ismessageLoading.isTyping2}
            bookDetailsData={bookingDetailData.bookDetailsData}
            detailsBtn={optionClick.detailsBtn}
            bookingemail={bookingDetailData.bookingemail}
            setIsTyping={ismessageLoading.isTyping}
            setIsTyping2={ismessageLoading.isTyping2}
            bookingDetailsEmailMsg={bookingDetailData.bookingDetailsEmailMsg}
            bookingDetailsLoader={messageLoaders.bookingDetailsLoader}
            handleContactUsClick={handleContactUsClick}
            contactUs={optionClick.contactUs}
            currentQuestionContactIndex={
              questionIndex.currentQuestionContactIndex
            }
            printSuccessBooking={confirmBooking.printSuccessBooking}
            contactmesssage={contactUsData.contactmesssage}
          />
          {optionClick.hotelClicked && (
            <AutoComplete
              currentQuestionIndex={questionIndex.currentQuestionIndex}
              searchData={autoSearch.searchData}
              handleCitySelection={handleCitySelection}
              setAutoSearch={setAutoSearch}
            />
          )}
          <FirstLoader
            loader={messageLoaders.loader}
            statusCodeHotel={hotelListingData.statusCodeHotel}
            statusCodeRoom={roomListingData.statusCodeRoom}
            statusCodeRoomAvailabe={roomListingData.statusCodeRoomAvailabe}
            bookbtn={roomListingData.bookbtn}
            sendData={hotelData.sendData}
          />
          {hotelListingData.statusCodeHotel === 404 && (
            <>
              <div className="question-div" style={{ display: "flex " }}>
                <div className="innr_box">No Hotels Found</div>
                <button
                  className="option"
                  style={{
                    height: "42px",
                    padding: "5px",
                    borderRadius: "9px",
                  }}
                  onClick={backButton}
                >
                  Start Again
                </button>
              </div>
            </>
          )}
          <div className="container">
            <div className="row">
              <HotelListing
                hotels={hotelData.hotels}
                messagesEndRef={messagesEndRef}
                selectRoom={roomListingData.selectRoom}
                setSortByPrice={setSortByPrice}
                currentPage={hotelData.currentPage}
                hotelsPerPage={hotelData.hotelsPerPage}
                handleHotelPrintClick={handleHotelPrintClick}
                handlePageChange={handlePageChange}
                statusCodeHotel={hotelListingData.statusCodeHotel}
                btn={hotelListingData.btn}
                statusCodeRoom={roomListingData.statusCodeRoom}
                hotelLoader={messageLoaders.hotelLoader}
                sendData={hotelData.sendData}
              />
              {roomListingData.statusCodeRoom === 404 && (
                <>
                  <div
                    className="question-div"
                    style={{ display: "-webkit-inline-box", marginTop: "10px" }}
                    ref={messagesEndRef}
                  >
                    <div className="innr_box">No Rooms Found</div>
                    <button
                      className="option"
                      style={{ height: "38px", marginTop: "3px" }}
                      onClick={backButton}
                    >
                      Start Again
                    </button>
                  </div>
                </>
              )}
            </div>
            <HotelButtons
              btn={hotelListingData.btn}
              currentPage={hotelData.currentPage}
              handlePrevPage={handlePrevPage}
              hotels={hotelData.hotels}
              hotelsPerPage={hotelData.hotelsPerPage}
              handleNextPage={handleNextPage}
              hotelLoader={messageLoaders.hotelLoader}
            />
            <SecondLoader
              loading={messageLoaders.loading}
              messagesEndRef={messagesEndRef}
              statusCodeRoom={roomListingData.statusCodeRoom}
              statusCodeRoomAvailabe={roomListingData.statusCodeRoomAvailabe}
            />
            <RoomListing
              loading={messageLoaders.loading}
              showInput={inputs.showInput}
              messagesEndRef={messagesEndRef}
              selectedHotelName={hotelListingData.selectedHotelName}
              selectedHotelRating={hotelListingData.selectedHotelRating}
              selectRoom={roomListingData.selectRoom}
              selectedRoomType={roomListingData.selectedRoomType}
              handleRoomTypeChange={handleRoomTypeChange}
              setBookRoom={roomListingData.bookRoom}
              setRoomListingData={setRoomListingData}
              handleRoomClick={handleRoomClick}
              bookbtn={roomListingData.bookbtn}
              statusCodeRoom={roomListingData.statusCodeRoom}
              RoomImg={roomListingData.RoomImg}
              roomImagesOpen={roomListingData.roomImagesOpen}
            />
          </div>
          <UserDetails
            responseSuccess={roomListingData.responseSuccess}
            userQuestion={questionListingData.userQuestion}
            currentUserQuestionIndex={questionIndex.currentUserQuestionIndex}
            userDetailsinputarray={inputs.userDetailsinputarray}
            messagesEndRef={messagesEndRef}
            backButtonUser={backButton}
            handleUserDetailsInput={handleUserDetailsInput}
          />
          {roomListingData.statusCodeRoomAvailabe === 404 && (
            <div
              className="question-div"
              style={{ display: "flex", marginTop: "10px" }}
              ref={messagesEndRef}
            >
              <div className="innr_box">No Rooms Available</div>
              <button
                className="option"
                style={{ height: "38px", marginTop: "3px" }}
                onClick={backButton}
              >
                Start Again
              </button>
            </div>
          )}
          <PaymentLoader
            showSuccessMsg={confirmBooking.showSuccessMsg}
            isTyping3={ismessageLoading.isTyping3}
            showLink={showLink}
            showConfirmMsg={confirmBooking.showConfirmMsg}
            isTyping4={ismessageLoading.isTyping4}
            printConfirm={printConfirm}
            Link={Link}
            numRooms={hotelData.numRooms}
            selectedRoomPrice={roomListingData.selectedRoomPrice}
            firstName={userData.firstName}
            lastName={userData.lastName}
            address={userData.address}
            postalCode={userData.postalCode}
            city={userData.city}
            bookingId={transactionDetails.bookingId}
            messagesEndRef={messagesEndRef}
            printSuccessBooking={confirmBooking.printSuccessBooking}
            statusCodeBooking={confirmBooking.statusCodeBooking}
            selectedHotelName={hotelListingData.selectedHotelName}
            confirmBookingStatus={confirmBooking.confirmBookingStatus}
            BookAgainButton={BookAgainButton}
            setPrintConfirm={setPrintConfirm}
          />
          {!ismessageLoading.isTyping4 && printConfirm === false
            ? hotelListingData.responseMessage === 200 && (
              <>
                <Payment
                  numRooms={hotelData.numRooms}
                  selectedRoomPrice={roomListingData.selectedRoomPrice}
                  firstName={userData.firstName}
                  lastName={userData.lastName}
                  address={userData.address}
                  postalCode={userData.postalCode}
                  city={userData.city}
                  bookingId={transactionDetails.bookingId}
                  messagesEndRef={messagesEndRef}
                  setPrintConfirm={setPrintConfirm}
                  transactionid={transactionDetails.transactionid}
                  clientsecret={transactionDetails.clientSecret}
                />
              </>
            )
            : hotelListingData.responseMessage === 400 ||
            (confirmBooking.confirmBookingStatus === 400 && (
              <>
                <div className="question-div">
                  <div className="innr_box" ref={messagesEndRef}>
                    Booking Failed
                  </div>
                </div>
                <button
                  className="book-again-btn-payment-failed"
                  onClick={BookAgainButton}
                >
                  Book Again
                </button>
              </>
            ))}
        </div>
        <>
          <SuccessBooking
            printSuccessBooking={confirmBooking.printSuccessBooking}
            BookAgainButton={BookAgainButton}
            transactionid={transactionDetails.transactionid}
            contactUs={optionClick.contactUs}
          />
        </>
        <HotelInput
          displayQuestions={questionListingData.displayQuestions}
          questionData={questionListingData.questionData}
          currentQuestionIndex={questionIndex.currentQuestionIndex}
          minDate={hotelData.minDate}
          setInputs={setInputs}
          handleKeyDown={handleKeyDown}
          handleUserInput={handleUserInput}
          userInput={inputs.userInput}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setMinDate={hotelData.minDate}
          hotelClicked={optionClick.hotelClicked}
        />
        {!printConfirm && (
          <UserInput
            bookbtn={roomListingData.bookbtn}
            userQuestion={questionListingData.userQuestion}
            currentUserQuestionIndex={questionIndex.currentUserQuestionIndex}
            handleKeyDown={handleKeyDown}
            setInputs={setInputs}
            userInput={inputs.userInput}
            handleUserDetailsInput={handleUserDetailsInput}
            autoFocus={autoFocus}
            setAutoFocus={setAutoFocus}
            printConfirm={printConfirm}
            statusCodeRoomAvailabe={roomListingData.statusCodeRoomAvailabe}
          />
        )}
        {optionClick.detailsBtn
          ? ""
          : !ismessageLoading.isTyping2 &&
          optionClick.bookingDetails &&
          questionListingData.questionData && (
            <BookingDetailsInput
              setOptionClick={setOptionClick}
              bookingemail={bookingDetailData.bookingemail}
              setBookingDetailData={setBookingDetailData}
              bookingDetails={optionClick.bookingDetails}
              autoFocus={autoFocus}
              setAutoFocus={setAutoFocus}
              userInput={inputs.userInput}
            />
          )}
        {optionClick.contactUs && (
          <ContactUsInput
            autoFocus={autoFocus}
            setAutoFocus={setAutoFocus}
            questionData={questionListingData.questionData}
            handleUserContactInput={handleUserContactInput}
            currentQuestionContactIndex={
              questionIndex.currentQuestionContactIndex
            }
            setInputs={setInputs}
            contactUs={optionClick.contactUs}
            userInput={inputs.userInput}
          />
        )}
      </div>
    </div>
  );
};
export default Chatbot;