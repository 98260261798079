import "../PaymentLoader/PaymentLoader.css";
const PaymentLoader = ({
  showLink,
  isTyping4,
  printConfirm,
  messagesEndRef,
  printSuccessBooking,
  confirmBookingStatus,
}) => {
  return (
    <>
      {showLink && isTyping4 && (
        <div className="question-div">
          <div className="innr_box">
            <div className="snippet">
              <div className="stage">
                <div className="dot-falling"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {printSuccessBooking
        ? ""
        : printConfirm &&
          confirmBookingStatus !== 400 && (
            <div
              className="question-div"
              style={{ marginTop: "-8px" }}
              ref={messagesEndRef}
            >
              <div className="innr_box">
                <div className="snippet">
                  <div className="stage">
                    <div className="dot-falling"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
    </>
  );
};
export default PaymentLoader;
