import React, { useState, useEffect, useRef } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "../CheckoutForm/Payment.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { print } from "../../Recoil/atom";

const CheckoutForm = ({ clientSecret,setPrintConfirm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const test = useRecoilValue(print);
  const messagesEndRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
      
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      window.localStorage.setItem("print", true);
      setPrintConfirm(true)
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success! Payment received.");
          break;
        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;
        case "requires_payment_method":
          setMessage("Payment failed. Please try another payment method.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end"  });
    }
  }, []);

  return (  
    <>
      <div className="main-div-payment">
        <form id="payment-form" onSubmit={handleSubmit}>
          <h3 className="header-payment">Enter your payment details</h3>
          <PaymentElement />
          <button className="payment-btn" disabled={!stripe}>
            Submit
          </button>
          {errorMessage && <div className="error-msg">{errorMessage}</div>}
        </form>
      </div>
      <div style={{height:'130px'}} ref={messagesEndRef}/>
    </>
  );
};

export default CheckoutForm;
