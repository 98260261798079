import { useEffect, useState, useRef } from "react";
import "../RoomListing/RoomListing.css";
const RoomListing = ({
  loading,
  showInput,
  messagesEndRef,
  bookbtn,
  statusCodeRoom,
  selectedHotelName,
  selectRoom,
  handleRoomClick,
  selectedRoomType,
  handleRoomTypeChange,
  RoomImg,
  roomImagesOpen,
  setRoomListingData,
}) => {
  const bookNowRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = RoomImg;
  const goToPreviousSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNextSlide = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    if (bookNowRef.current) {
      bookNowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [bookNowRef.current]);
  return (
    <>
      {statusCodeRoom != 404 && loading === false && showInput && (
        <div className="main-room">
          {!roomImagesOpen && (
            <p className="header-room">Hotel Name : {selectedHotelName}</p>
          )}
          {roomImagesOpen && <p className="header-room">Images Of Rooms</p>}
          {!roomImagesOpen && (
            <p
              className="room-images-p"
              onClick={() =>
                setRoomListingData((prevState) => ({
                  ...prevState,
                  roomImagesOpen: true,
                }))
              }
            >
              View Images
            </p>
          )}
          {roomImagesOpen && (
            <div className="image-slider" ref={messagesEndRef}>
              {/* Rooms Images */}
              <button
                className="image-close-btn"
                onClick={() =>
                  setRoomListingData((prevState) => ({
                    ...prevState,
                    roomImagesOpen: false,
                  }))
                }
              >
                {" "}
                <i
                  style={{ fontSize: "21px", color: "white" }}
                  className="bi bi-x-lg close"
                ></i>{" "}
              </button>
              <button className="slider-button-l" onClick={goToPreviousSlide}>
                <i
                  style={{ fontSize: "32px", color: "white" }}
                  className="bi bi-caret-left-fill"
                ></i>
              </button>
              <img
                src={images[currentIndex]}
                alt="Slider"
                className="slider-image"
              />
              <button className="slider-button-r" onClick={goToNextSlide}>
                <i
                  style={{
                    fontSize: "32px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  className="bi bi-caret-right-fill"
                ></i>
              </button>
            </div>
          )}
          {!roomImagesOpen && (
            <div className="selected-hotel-div" ref={messagesEndRef}>
              <p className="hotel-p1">Select Type Of Room</p>
            </div>
          )}

          <div className="room-div">
            {!roomImagesOpen &&
              selectRoom.map((item, index) => (
                <div className="inner-room-div" key={`${item.id}-${index}`}>
                  <div
                    className="inner-room-detail"
                    key={`${item.id}-${index}`}
                  >
                    <span className="room-title" ref={bookNowRef}>
                      {item.title}
                    </span>
                    <span className="room-price">{`$ ${Math.round(
                      item.total_price
                    )}`}</span>
                  </div>
                  <input
                    type="checkbox"
                    checked={selectedRoomType === item.title}
                    onChange={handleRoomTypeChange}
                    name="roomType"
                    onClick={() => {
                      setRoomListingData((prevState) => ({
                        ...prevState,
                        bookRoom: true,
                      }));
                      handleRoomClick(item);
                    }}
                    value={item.title}
                    ppn_bundle={item.ppn_bundle}
                    disabled={bookbtn}
                    style={{
                      marginTop: "6px",
                      width: "16px",
                      height: "20px",
                      borderRadius: " 10px",
                    }}
                  />
                </div>
              ))}
            {!roomImagesOpen && selectedRoomType && (
              <button
                className="Room-Btn"
                onClick={() =>
                  setRoomListingData((prevState) => ({
                    ...prevState,
                    bookbtn: true,
                  }))
                }
              >
                Book Now
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default RoomListing;
