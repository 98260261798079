import React, { useEffect, useRef } from "react";
import "../UserDetails/UserDetails.css";
const UserDetails = ({
  responseSuccess,
  userQuestion,
  currentUserQuestionIndex,
  userDetailsinputarray,
  messagesEndRef,
  backButtonUser,
  handleUserDetailsInput,
}) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentUserQuestionIndex]);
  return (
    <>
      {responseSuccess === 200 &&
        userQuestion?.map((question, index) => {
          if (index === currentUserQuestionIndex) {
            setTimeout(() => {
              // show question content after 3 seconds
              const questionElement = document.getElementById(
                `question-${index}`
              );
              if (questionElement) {
                questionElement.style.display = "inline-block";
              }
              // hide typing indicator
              const typingElement = document.getElementById(`typing-${index}`);
              if (typingElement) {
                typingElement.style.display = "none";
              }
              if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }, 100); // 3 seconds
            return (
              <React.Fragment key={index}>
                <div
                  className="question-div"
                  key={index}
                  style={{ marginTop: "10px" }}
                >
                  <div
                    id={`question-${index}`}
                    className="innr_box"
                    style={{ display: "none" }}
                  >
                    {question.content}
                    {currentUserQuestionIndex === 0 && (
                      <div style={{ display: "flex" }} ref={scrollRef}>
                        <button
                          style={{
                            fontSize: "15px",
                            padding: "8px",
                            borderRadius: "13px",
                            marginRight: "10px",
                            marginTop: "9px",
                          }}
                          className="option"
                          onClick={() => handleUserDetailsInput("yes")}
                        >
                          Yes
                        </button>
                        <button
                          style={{
                            fontSize: "15px",
                            padding: "8px",
                            borderRadius: "13px",
                            marginRight: "10px",
                            marginTop: "9px",
                          }}
                          onClick={() => {
                            backButtonUser();
                          }}
                          className="option"
                        >
                          No
                        </button>
                      </div>
                    )}
                  </div>
                  <div id={`typing-${index}`} className="innr_box">
                    <div className="snippet">
                      <div className="stage">
                        <div className="dot-falling"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="user-input-value"
                  key={`input-${index}`}
                  style={{ display: "none" }}
                >
                  <div id={`user-input-${index}`} className="innr_box">
                    {userDetailsinputarray[index]}
                  </div>
                </div>
              </React.Fragment>
            );
          } else if (userDetailsinputarray[index]) {
            setTimeout(() => {
              // show user input value after 3 seconds
              const userInputElement = document.getElementById(
                `user-input-${index}`
              );
              if (userInputElement) {
                userInputElement.style.display = "inline-block";
              }
            }, 100); // 3 seconds
            return (
              <React.Fragment key={index}>
                <div
                  className="question-div"
                  key={index}
                  style={{ marginTop: "10px" }}
                >
                  <div className="innr_box" ref={messagesEndRef}>
                    {question.content}
                  </div>
                </div>
                <div
                  className="user-input-value"
                  key={`input-${index}`}
                  ref={messagesEndRef}
                >
                  <div className="innr_box">{userDetailsinputarray[index]}</div>
                </div>
              </React.Fragment>
            );
          }
          return null;
        })}
    </>
  );
};
export default UserDetails;
