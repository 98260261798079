import React from "react";
import { Routes, Route } from "react-router-dom";
import Chatbot from "./Pages/Chatbot";
import Payment from "../src/Components/Payment/Payment";
import { RecoilRoot } from "recoil";
const App=()=>{
  return (
    <>
      <RecoilRoot>
      <Routes>
        <Route exact path="/" element={<Chatbot />}></Route>
        <Route path="/payment" element={<Payment />} />
      </Routes>
      </RecoilRoot>
    </>
  );
}
export default App;
