import "../SuccessBooking/SuccessBooking.css";
const SuccessBooking = ({
  printSuccessBooking,
  BookAgainButton,
  transactionid,
  contactUs,
}) => {
  return (
    <>
      {printSuccessBooking && (
        <>
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark draw"></div>
          </div>
          <div style={{ marginTop: "-53px", marginRight: "11px" }}>
            {!contactUs && (
              <p
                className="sucess-p"
                style={{ color: "green", fontSize: "16px" }}
              >
                Payment Successfull!
              </p>
            )}
            {!contactUs && (
              <p className="sucess-p">
                All the booking details has been sent via mail
              </p>
            )}
            {contactUs && (
              <p
                className="sucess-p"
                style={{ color: "green", fontSize: "15px" }}
              >
                Thank You For Your Feedback
              </p>
            )}
            {!contactUs && (
              <p className="sucess-id">
                Your Transaction Id is : {transactionid}
              </p>
            )}
            {contactUs && (
              <p
                className="sucess-id"
                style={{ fontSize: "13px", fontWeight: "bold" }}
              >
                We will get back to you soon
              </p>
            )}
            {!contactUs && (
              <button className="book-again-btn" onClick={BookAgainButton}>
                Book Again
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default SuccessBooking;