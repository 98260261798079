import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
const Payment = ({
  messagesEndRef,
  setPrintConfirm,
  selectedRoomPrice,
  selectedhotelName,
  clientsecret,
}) => {
  const [stripePromise, setStripePromise] = useState(
    loadStripe("pk_test_Ia3SvRzdMVnLCRkodQeMdmZW")
  );
  const [clientSecret, setClientSecret] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  useEffect(() => {
    fetch("/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);
  useEffect(() => {
    setClientSecret(clientsecret);
  }, [clientSecret]);
  return (
    <>
      {!clientSecret && !stripePromise && (
        <div className="loader" ref={messagesEndRef}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
          <div className="bar4"></div>
          <div className="bar5"></div>
          <div className="bar6"></div>
          <div className="bar7"></div>
          <div className="bar8"></div>
          <div className="bar9"></div>
          <div className="bar10"></div>
          <div className="bar11"></div>
          <div className="bar12"></div>
        </div>
      )}
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            messagesEndRef={messagesEndRef}
            clientSecret={clientSecret}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            selectedhotelName={selectedhotelName}
            selectedRoomPrice={selectedRoomPrice}
            setPrintConfirm={setPrintConfirm}
          />
        </Elements>
      )}
      <div style={{ display: "none" }}></div>
    </>
  );
};
export default Payment;