import { useState, useRef, useEffect } from "react";
import "../BookingDetailsInput/BookingDetailsInput.css";
const BookingDetailsInput = ({ setOptionClick, setBookingDetailData }) => {
  const [btnDisbaled, setBtnDisabled] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <form
      onSubmit={() =>
        setOptionClick((prevState) => ({
          ...prevState,
          detailsBtn: true,
        }))
      }
    >
      <div className="input-div">
        <div className="inside-input">
          <input
            placeholder="Type Your Message Here"
            type="email"
            onChange={(e) => {
              const input = e.target.value;
              const sendButton = document.querySelector(".send-btn");
              const regex = /^\S+@\S+\.\S+$/;
              const isValidEmail = regex.test(input);
              setBookingDetailData((prevState) => ({
                ...prevState,
                bookingemail: input,
              }));
              if (isValidEmail) {
                setBtnDisabled(true);
              }
              if (input.length > 40) {
                e.target.value = input.slice(0, 40);
              }
            }}
            className="user-input"
            ref={inputRef}
          ></input>
          <button
            type="submit"
            className="send-btn"
            disabled={!btnDisbaled}
            onClick={() =>
              setOptionClick((prevState) => ({
                ...prevState,
                detailsBtn: true,
              }))
            }
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0958 0.334004C18.2824 -0.277135 21.08 2.52049 20.4689 5.70708L18.7142 14.8561C18.3328 16.845 16.8274 18.4355 14.8712 18.9409C10.9656 19.9498 7.39617 16.3277 8.53984 12.4386C8.57143 12.3312 8.47164 12.2314 8.36424 12.263C4.47515 13.4067 0.853112 9.83728 1.86202 5.93169C2.36735 3.97552 3.95789 2.47008 5.94673 2.08865L15.0958 0.334004ZM18.9957 5.42456C19.4072 3.2792 17.5237 1.39571 15.3783 1.80716L6.22926 3.5618C4.81437 3.83315 3.67585 4.90743 3.31435 6.30686C2.59178 9.10398 5.18864 11.6334 7.94105 10.824C9.18743 10.4574 10.3454 11.6154 9.97891 12.8618C9.16949 15.6143 11.6989 18.2111 14.496 17.4886C15.8955 17.127 16.9697 15.9885 17.2411 14.5736L18.9957 5.42456ZM2.94454 13.6157C3.23744 13.9086 3.23744 14.3835 2.94454 14.6764L1.53033 16.0906C1.23744 16.3835 0.762563 16.3835 0.46967 16.0906C0.176777 15.7977 0.176777 15.3228 0.46967 15.0299L1.88388 13.6157C2.17678 13.3228 2.65165 13.3228 2.94454 13.6157ZM6.48008 14.3228C6.77297 14.6157 6.77297 15.0906 6.48008 15.3835L5.06586 16.7977C4.77297 17.0906 4.2981 17.0906 4.0052 16.7977C3.71231 16.5048 3.71231 16.0299 4.0052 15.737L5.41942 14.3228C5.71231 14.0299 6.18718 14.0299 6.48008 14.3228ZM7.18718 17.8584C7.48008 18.1512 7.48008 18.6261 7.18718 18.919L5.77297 20.3332C5.48008 20.6261 5.0052 20.6261 4.71231 20.3332C4.41942 20.0403 4.41942 19.5655 4.71231 19.2726L6.12652 17.8584C6.41942 17.5655 6.89429 17.5655 7.18718 17.8584Z"
                fill="#F2F4F5"
              />
            </svg>
          </button>
        </div>
        <div className="input-bottoms">
          MADE WITH{" "}
          <p
            style={{
              position: "relative",
              top: "8px",
              marginLeft: "3px",
              marginRight: "3px",
            }}
          >
            <i style={{ color: "#0069d9" }} className="bi bi-heart-fill"></i>{" "}
          </p>{" "}
          BY TEAM ONECLICK
        </div>
      </div>
    </form>
  );
};

export default BookingDetailsInput;
