import React from "react";

const FirstLoader = ({
  loader,
  statusCodeHotel,
  statusCodeRoom,
  statusCodeRoomAvailabe,
  bookbtn,
  sendData,
}) => {
  if (statusCodeHotel === 400) {
    loader = false;
  }
  if (statusCodeRoom === 400) {
    loader = false;
  }
  if (statusCodeRoomAvailabe === 404) {
    loader = false;
  }
  return (
    <>
      {bookbtn === false && loader ? (
        <div className="question-div">
          {sendData && <div className="innr_box">Searching for Hotels...</div>}
        </div>
      ) : null}
    </>
  );
};

export default FirstLoader;
