import React from "react";
import "../HotelButtons/HotelButtons.css";
const HotelButtons = ({
  btn,
  currentPage,
  handlePrevPage,
  hotels,
  hotelsPerPage,
  handleNextPage,
}) => {
  return (
    <>
      {" "}
      {!btn && currentPage > 1 && (
        <button className="previous-btn" onClick={handlePrevPage}>
          Previous
        </button>
      )}
      {!btn && hotels.length > currentPage * hotelsPerPage && (
        <button className="next-btn" onClick={handleNextPage}>
          Next
        </button>
      )}
    </>
  );
};
export default HotelButtons;
