import React from "react";
import HeaderImg from "../../Assets/Images/Header-Img.png";
import "../Header/Header.css";
const Header = ({
  backButton,
  printConfirm,
  responseMessage,
  about,
  bookingDetails,
  currentUserQuestionIndex,
  contactUs,
  currentQuestionContactIndex,
  hotelClicked,
  setIsmessageLoading,
  setOptionClick,
}) => {
  return (
    <div
      className={
        currentUserQuestionIndex >= 1
          ? "headers"
          : currentQuestionContactIndex >= 1
          ? "header-2"
          : "header"
      }
    >
      <img className="header-img" src={HeaderImg} />
      <div className="header-info">
        <p className="header-p1">Travel Bot</p>
        <p className="header-p2">Bot</p>
      </div>
      {printConfirm === false && responseMessage === 200
        ? ""
        : hotelClicked &&
          printConfirm === false && (
            <button
              className={
                currentUserQuestionIndex >= 1 ||
                currentQuestionContactIndex >= 1
                  ? "back_btns"
                  : "back_btn"
              }
              onClick={() => {
                backButton();
              }}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          )}
      {about && (
        <button
          className="back_btn"
          onClick={() => {
            backButton();
          }}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      )}
      {contactUs && (
        <button
          className={
            currentUserQuestionIndex >= 1
              ? "back_btns"
              : currentQuestionContactIndex >= 1
              ? "back-btn-contact"
              : "back_btn"
          }
          onClick={() => {
            backButton();
          }}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      )}
      {bookingDetails && (
        <button
          className="back_btn"
          onClick={() => {
            setOptionClick((prevState) => ({
              ...prevState,
              bookingDetails: false,
            }));
            setOptionClick((prevState) => ({
              ...prevState,
              selectedOption: null,
            }));
            setIsmessageLoading((prevState) => ({
              ...prevState,
              isTyping2: false,
            }));
            setOptionClick((prevState) => ({
              ...prevState,
              detailsBtn: false,
            }));
          }}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      )}
    </div>
  );
};
export default Header;
