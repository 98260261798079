import React from "react";
import "../AutoComplete/AutoComplete.css";
const AutoComplete = ({
  currentQuestionIndex,
  searchData,
  handleCitySelection,
  setAutoSearch,
}) => {
  return (
    <>
      {currentQuestionIndex === 0 ? (
        searchData.length > 0 ? (
          <>
            <div className="auto-complete-container">
              {searchData.map((item) => (
                <div
                  className="auto-complete"
                  key={item.id}
                  onClick={() => {
                    handleCitySelection(item.city);
                    setAutoSearch((prevState) => ({
                      ...prevState,
                      cityID: item.cityPpn,
                    }));
                  }}
                >
                  {item.city}
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};
export default AutoComplete;
